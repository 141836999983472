.card-profile{
    margin: 20px 0px 20px 0px;
}

.white-button-cancel{
    border: solid 2px rgba(173, 32, 201, 1);
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
    color: rgba(173, 32, 201, 1);
}

.white-button-save{
    border: solid 2px rgba(173, 32, 201, 1);
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
    color: white;
    background-color: rgba(173, 32, 201, 1);
}

.white-button-save:hover{
    scale: 1.1;
}

.white-button-cancel:hover{
    scale: 1.1;
}

.avatar-container {
   position: relative;
}

.file-upload {
    opacity: 0;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    /* IE 5-7 */
    filter: alpha(opacity=0); 
    /* Netscape or and older firefox browsers */
    -moz-opacity: 0.0;
    /* older Safari browsers */
    -khtml-opacity: 0.0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    height:inherit;
 }

 #avatar {
    width: 100px;
    height: 100px;
    font-size: 50px;
    background-color: #555555;
 }

 .avatar-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    height:100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555555;
    background-color: #ffffffe8;
    text-align: center;
    border-radius: 50%;
    border: solid 1px #555555;
    padding: 5px;
 }