.sign-up-page {
    background-color: #AC00B2;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-with{
    display: flex;
    justify-content: center;
    margin-top: -10px;
}
