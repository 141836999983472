.text-input {
  margin-bottom: 3rem;

  .input-container {
    border-bottom: 1px solid #999;

    &.multiline {
      border: 1px solid #999;
      border-radius: 5px;
      padding: 0 10px;      
    }

    .text-input-input {
      width: 100%;
      border: 0;
      resize: none;  
      outline: none; 
      border-radius: 5px; 
      font-size: 14px;
  
      &::placeholder {
        color: #999;        
      }
    }
  }
  
  .text-input-label {
    font-weight: bold;
    color: #333;  
    font-size: 14px;  
  }
}