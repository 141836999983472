.image-container-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-image {
  width: 100%;
  // height: 80%;
}
