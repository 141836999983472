.review-aspect { 
  padding: 0 0 4rem; 

  .title {
    color: #333;
    margin-bottom: 20px;
  }

  .description {
    color: #333;
    margin-bottom: 20px;
  }

  .aspect-item {
    border-radius: 15px;
    padding: 0.1rem 1rem;
    text-transform: capitalize;
    font-size: 14px;
    margin: 0 0.5rem 0.5rem 0;
    outline: none;
  }

  .other-text {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;

    .other-text-input {
      font-size: 14px;      
    }
  }

  .divider {
    box-shadow: 0px 2px 2px #eba7f7;
    height: 1px;
    position: relative;
    margin-bottom: 60px;

    &::before {
      content: '';
      width: 0; 
      height: 0; 
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;      
      border-top: 15px solid #f6d9fb;
      position: absolute;
      left: calc(50% - 1rem);
      bottom: -15px;      
    }

    &::after {
      content: '';
      width: 0; 
      height: 0; 
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;      
      border-top: 15px solid #ffffff;
      position: absolute;
      left: calc(50% - 1rem);
      bottom: -12px;
    }
  }
}