.custom-searchbar {
    position: relative;

    .input-container {
        .input {
            border: purple 2px solid;
            border-radius: 50px;
            width: 100%;
            padding: 10px;
            text-align: center;
            background-color: rgba(255, 255, 255, 0);
        }
    }

    .icon {
        position: absolute;
        .loader-icon {
        }
    }

    .search-result {
        position: absolute;
        top: 100%;
        // left: 25vw;
        width: 100%;
        z-index: 9999;
        box-shadow: 0 5px 5px #55555555;
        max-height: 60vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .result-item:hover {
            background-color: rgba(128, 0, 128, 0.555);
            color: white;
            p {
                color: white;
            }
        }
    }
}
