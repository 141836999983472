.verify-email-page {    
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .verify-email-container {
        background-color: #AC00B2;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;

        .verify-email-wrapper {
            background-color: #FFFFFF;
            padding: 2rem;
            border-radius: 12px;            

            .verify-success-image {
                height: 75px;
                margin-bottom: 1rem;
            }

            p {
                color: #999999;
                margin-bottom: 2rem;
            }

            .btn {
                border-radius: 20px;
            }
        }
    }
}
