.sign-in-page {
    background-color: #AC00B2;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sign-in-container { 
    width: 350px;
    height: 350px;
    overflow: hidden;
    position: relative; 
    border-radius: 10px;
    background-color: #ffffff;
    
    &.email {
        .sign-in-form {
            &:first-child {
                left: -350px;
            }

            &:last-child {
                left: 0px;
            }
        }
    }
}
.sign-in-form {
    position: absolute;
    width: 350px;
    left: 0;
    transition: .2s;

    &:last-child {
        left: 350px;
    }

    .back-button {
        font-weight: bold;
        padding: 0px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        left: 15px;
        background: #9c27b0!important;
    }

    .header-container {
        position: relative;
    }

    .email-address {
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}