.on-board-main {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 50px;

    .input-field{
        width: 70%;
    }

    .divider {
        box-shadow: 0px 2px 2px #eba7f7;
        height: 1px;
        position: relative;
        margin-bottom: 60px;
    
        &::before {
          content: '';
          width: 0; 
          height: 0; 
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;      
          border-top: 15px solid #f6d9fb;
          position: absolute;
          left: calc(50% - 1rem);
          bottom: -15px;      
        }
    
        &::after {
          content: '';
          width: 0; 
          height: 0; 
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;      
          border-top: 15px solid #ffffff;
          position: absolute;
          left: calc(50% - 1rem);
          bottom: -12px;
        }
      }
}

.review-submitted-dialog {
  .MuiPaper-rounded {
      border-radius: 10px;
  }
  .review-submitted-card {
      padding: 3rem 2rem!important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p.description {
          text-align: center;
      }
      .btn-ok {
          width: 100px;
      }
  }
}