.row-container {
  background-color: #ac00b2;
  padding: 0px 10px 0px 10px;
}

.image-container{
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .carousel-container{
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .single-image-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.white-font {
  color: white;
  padding: 0px 5px 0px 5px;
}

.block-account-button {
  background-color: #9500b2;
  text-align: center;
  padding: 15px;
}

.like-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background-color: #2e2e2e31;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -99;
  font-size: 100px;
  transition: ease-in-out 0.5s;
}

.close-button {
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: rgba(255, 255, 255, 0.548);
  z-index: 99;
  font-family: monospace;
  color: white;
}

.like-icon {
  width: 15px;
  height: 15px;
  vertical-align: top;
  margin-right: 5px;
  margin-left: 10px;
}

.like-icon:hover {
  scale: 1.1;
  -webkit-transform: scale(1.1);

}

.my-avatar:hover{
  cursor: pointer;
}
