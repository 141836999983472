.home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // height: 100vh;
}

.main-banner {
    background-image: url("../../assets/images/banner-bg.png");
    height: 40vh;
    // background-color: red;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-text {
    background-color: white;
    border-radius: 30px;
    padding: 5px 30px 5px 30px;
}

.banner-text:hover {
    scale: 1.1;
    transition: 0.5s;
}

.banner-link:hover {
    color: #9600b3;
}

.middle-container {
    top: -5vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.middle-toolbar {
    width: 80%;
    // transition: height ease 0.4s;
}

.middle-icon {
    padding: 30px 0px 30px 0px;
    display: flex;
    align-items: flex-end;
}

.body-container-home {
    margin-top: 2vh;
}

.content-container {
    margin-top: 5vh;
    margin-bottom: 70px;
}

// @media only screen and (max-width: 600px) {
//     .content-container {
//         width: 100vw;
//     }
// }

.body-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;

    &.bg-purple {
        background-color: #9600b3;
    }

    &.bg-light-grey {
        background-color: #e6e6e6;
    }
}

.text-purple {
    color: #9600b3;
}
.text-light-grey {
    color: #e6e6e6;
}

.trending-container-main {
    width: inherit;
    
    .trending-container {
        width: inherit;
        overflow-x: scroll;
        display: flex;
        align-items: center;
        
        .load-more {
            margin-left: 20px;
            margin-right: 20px;
            
            button {
                //    height: 3;
            }
        }
    }
}