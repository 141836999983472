.review-page {
    margin: 3rem auto;    

    .review-container {
        min-height: 100px;
        background-color: #FFFFFF;     
        border-radius: 10px;   
        margin: 1rem auto 1rem;
        border: 1px solid #DDDDDD;
        box-shadow: 0px 0px 2px #DDDDDD; 
        position: relative;       

        .review-wrapper {
            padding: 2rem;
        }
    
        .submit-button {
            width: 100%;
            padding: 0.5rem;
            font-size: 1.75rem;  
            border-radius: 0 0 10px 10px;             
        }

        .app-loader {
            align-items: flex-start;

            .lds-ripple {
                margin-top: 10rem;
            }
        }
    }   
}

.review-submitted-dialog {
    .MuiPaper-rounded {
        border-radius: 10px;
    }
    .review-submitted-card {
        padding: 3rem 2rem!important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p.description {
            text-align: center;
        }
        .btn-ok {
            width: 100px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .review-container {
        width: 70%;   
    }
}