.main-container{
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px;
    border-radius: 10px;
}

.content-container{
    margin-left: 20px;
}

.contact-us-form{
    width: 100%;
    border: none;
    height: 650px;
    margin-top: 50px;
    margin: -10px;
}