.main-container{
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px;
    border-radius: 10px;

    .image-content{
        margin-top: 50px;
        margin-bottom: 50px;
        width: 60%;
    }
}

.commentbox{
    // padding: 50px;
    // height: 50vh;
}
