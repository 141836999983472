.image-upload {
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column; 
  align-items: center;  
  position: relative; 
  
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }

  .legend-text {
    font-size: 12px;
    font-style: italic;
    color: #666;    
    position: relative;
    top: -20px;
  }

  .upload-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
  }

  .upload-button {
    border-radius: 25px;
    font-weight: bold;
    font-size: 14px;
    border-color: #666;
    outline: none;

    .create-icon {
      width: 22px;
      margin-right: 5px;
    }
  }

  .image-list {
    margin-bottom: 1rem;
    
    .file-item-container {
      width: 100px;
      height: 130px;
      float: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 10px 10px 40px 10px;
      border: 1px solid #CCC;
      border-radius: 10px;
      margin: 0 1rem 1rem 0;
      background-color: #F1F1F1;

      .file-item-icon {
        width: 32px;
      }
      .file-item-image {
        width: 100%;
      }
      .file-item-name {
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100px;
        position: absolute;
        bottom: 20px;
        padding: 0 10px;
      }
      .file-item-delete {
        border: none;
        background:#9c27b0;
        color: #FFFFFF;
        font-size: 10px;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 0 0 10px 10px;
        padding: 3px 0;
        outline: none;
      }
    }
  }
}