.feed-container:hover{
  opacity: .8;
}

.feed-container{
  margin-bottom: 50px;
  text-align: start;
}

.feed-container-main{
  box-shadow: #d1d1d1 0px 0px 10px;
  background-color: white;
  border-radius: 9px;
  width: inherit;
  min-height: 450px;


  .image-container{
    height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-self: center;

    .image-image{
      width: 100%;
      height: 100%;
    }
  }
}

.feed-container-main-trending{
  box-shadow: #d1d1d1 0px 0px 10px;
  border-radius: 9px;
  width: 300px;
  min-height: 450px;
  margin: 5px;
  
  .image-container{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 300px;
    height: 300px;
    
    .image-image{
      width: 100%;
      height: 100%;
    }
  }
}

.white-font{
  color: white;
  padding: 0px 5px 0px 5px;
}

.user-header{
  background-color: #A72EBD;
  padding: 5px;
  border-radius: 9px 9px 0px 0px;
  opacity: .8;
  height: 40px;
}

.detail-card{
  border-radius: 0px 0px 9px 9px;
  padding: 5px;
  background-color: white;
}