.review-tell-us-more {  
  padding: 0 0 2rem; 

  .title {
    color: #333;
    margin-bottom: 20px;
  }

  .sub-title {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }

  .legend-text {
    font-size: 12px;
    font-style: italic;
    color: #666;    
    position: relative;
    top: -20px;
  }

  .issue-list {
    margin-bottom: 3rem;
    
    .issue-item {
      border-radius: 15px;
      padding: 0.1rem 1rem;
      text-transform: capitalize;
      font-size: 14px;
      margin: 0 0.5rem 0.5rem 0;
      outline: none;
    }
  }  
  .map-container {
    .leaflet-container {
      height: 300px;
      margin-bottom: 3rem;
    }
  }

  .divider {
    box-shadow: 0px 2px 2px #eba7f7;
    height: 1px;
    position: relative;
    margin-bottom: 60px;    

    &::before {
      content: '';
      width: 0; 
      height: 0; 
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;      
      border-top: 15px solid #f6d9fb;
      position: absolute;
      left: calc(50% - 1rem);
      bottom: -15px;      
    }

    &::after {
      content: '';
      width: 0; 
      height: 0; 
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;      
      border-top: 15px solid #ffffff;
      position: absolute;
      left: calc(50% - 1rem);
      bottom: -12px;
    }
  }
}


@media screen and (min-width: 1200px) {
  .form-container {
      width: 60%;   
      margin: auto;
  }
}

#submit-button{
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  font-size: 25px;
}