.forgot-password-page {
    background-color: #AC00B2;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgot-password-container { 
    width: 350px;
    height: 316px;
    overflow: hidden;
    position: relative; 
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgot-password-form {
    .header-container {
        position: relative;
    }

    .email-sent-success {
        padding: 0 2rem;
        img {
            width: 125px;
            margin-bottom: 1rem;
        }
        h2 {
            color: #333;
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
        p {
            color: #999;            
        }
    }
}