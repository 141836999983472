.footer-container{
    // background-color: #F5F5F5;
    // bottom: 0;
    // height: 30vh;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // position: relative;
    // padding: 20px;
}

.footer-row-main{
    width: 90vw;
    display: flex;
    flex-direction: column;
}

.footer-row{
    text-align: start;
    padding: 20px;
}

.link{
    color: white;
}

.link:hover{
    color: rgb(168, 168, 168);
}