#report-chip {
    margin: 5px;
}

// #report-chip:hover {
//     background-color: #965fa1;
//     color: #555555;
// }

.report-container {
    //   border: #b9b8b8 solid 2px;
    border-radius: 1px;
    // max-width: 50vw;
}

.report-container-sub {
    padding: 10px;
    border-bottom: #b9b8b8 solid 0.5px;
}

.back-button-container {
    margin-bottom: 20px;
    .back-button {
        width: 20px;
        height: 20px;
        font-weight: bold;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 10px;
        background: #9c27b0 !important;
    }
}
