.review-feeling {  
  .title {
    color: #333;
    margin-bottom: 20px;
  }
  .feeling-button {
    border-radius: 10px;
    margin: 0 1rem;
    outline: none;

    .MuiIconButton-label {
      flex-direction: column;
    }
    .feeling-icon {
      width: 50px;
      height: 50px;      
      border-radius: 50%;
      margin-bottom: 10px;

      &.active {
        box-shadow: 0px 0px 15px #9700B2;
        border: 1px solid #9700B2;
      }
    }
    .description {      
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
  }
}
