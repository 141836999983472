.upper-container{
    background-color: #F8F9FA;
    width: 100vw;
    margin-left: -20px;
    padding-top: 30px;
    padding-bottom: 20px;
}

.chips-placeholder{
    padding-bottom: 20px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    // background-color: red;

    .chip{
        margin: 5px;
        padding: 0px 10px 0px 10px;
        background-color: #333333;
        color: white;
        font-weight: bold;
    }

    .chip:hover{
        scale: 1.05;
    }
}

.view-by-button{
    border: solid 2px #900093;
    padding: 5px 20px 5px 20px;
    border-radius: 20px;
    color: #900093;
    background-color: rgba(255, 255, 255, 0);
}

#mapSearchContainer {
    position: fixed;
    top: 20px;
    right: 40px;
    height: 30px;
    width: 180px;
    z-index: 110;
    font-size: 10pt;
    color: #5d5d5d;
    border: solid 1px #bbb;
    background-color: #f8f8f8;
  }
  
  .geocoder-control-input {
    background-image: url("https://raw.githubusercontent.com/Esri/esri-leaflet-geocoder/master/src/img/search.png");
  }

  .body-container {
      margin-bottom: 10vh;
      margin-top: 2vh;
  }